<template>
  <transition name="fade">
    <div class="bgcn" v-show="open">
      <transition name="drop-in">
        <div class="main-container">
          <div class="header">
            <span class="title">Info</span>
            <button @click="close()" class="btncls">X</button>
          </div>
          <div class="info-box">
            <div class="info-box-content">
              <!-- <div class="info-box-content-head">
                <img src="../assets/check.png" alt="" />
                <p>
                  You have Successfully Placed bet on
                  <strong>Chennai Super Kings</strong> for Match
                </p>
              </div> -->
              <div class="info-box-content-main">
                <div class="img-container">
                  <img src="../assets/warning.png" alt="" />
                </div>
                <h2>Already Joined</h2>
                <h3>Please Join Another Match</h3>
                <p>
                  Visit
                  <span @click="goToJoinedContext()">Joined Matches</span>
                  <!-- Section for Already Joined Matches -->
                </p>
              </div>
              <div class="info-box-content-footer">
                <div class="info-box-content-footer-title">
                  <span> NFT Balance </span>
                </div>
                <div class="info-box-content-footer-content">
                  <div class="footer-el">
                    <p>Total Used</p>
                    <span>{{ used }}</span>
                  </div>
                  <div class="footer-el">
                    <p>Available</p>
                    <span>{{ available }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span class="alert"
              >You have chosen <span class="tname">{{ team }}</span> as your
              winner.</span
            > -->
            <!-- <span class="alert"
              >Winning prize <span class="tname">{{ reward_amt }} {{token_symbol}}</span>
              </span -->
            <!-- > -->
            <!-- <span class="alert">Click on confirm button to predict.</span>
            <button class="confmb" @click="onClickButton()">Confirm</button> -->
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    img: String,
    available: Number,
    used: Number,
  },
  methods: {
    close() {
      this.$emit("closed", this.open);
    },
    goToJoinedContext() {
      this.$router.push({ name: "joinnedContest" });
    },
  },
};
</script>

<style scoped>
.bgcn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1032;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tname {
  color: #dda20c;
  font-size: 25px;
}
.alert {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  color: #ffffff;
}
.main-container {
  background-color: black;
  /* display: grid; */
  width: 600px;
  /* height: 300px; */
  border-radius: 15px;
  font-family: "Roboto";
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 600px;
  height: 48px;
  justify-content: space-between;
  background-color: #1c1c1c;
  border-radius: 15px 15px 0 0;
  padding: 0px;
}
.title {
  margin-top: 5px;
  font-family: "Roboto";
  font-size: 26px;
  color: #dda20c;
  font-weight: 700;
  margin-left: 14px;
}
/* .about {
        display: grid;
        padding: 10px;
        margin:30px;
    } */

.btncls {
  background-color: transparent;
  border: 3px solid #dda20c;
  color: #dda20c;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
  font-weight: bolder;
  margin: 0px;
  cursor: pointer;
  border-radius: 10px;
  width: 25px;
  height: 25px;
  margin: 10px;
}

h2 {
  color: #dda20c;
  font-size: 30px;
}
.name {
  color: #ffed50;
  font-size: 20px;
  text-align: center;
}
.info-box {
  width: 600px;
  height: max-content;
}
.info-box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  border-radius: 0px 0px 8px 8px;
}
.info-box-content-head {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 20px 0;
}
.info-box-content-head img {
  margin-top: 0px;
  width: 48px;
  height: 48px;
}
.info-box-content-head p {
  margin-left: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  /* padding-bottom: 0; */
  margin-bottom: 0;
}
.info-box-content-head strong {
  color: #dda20c;
}
.info-box-content-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #303030;
  margin-top: 15px;
  margin-bottom: 20px;
}
.info-box-content-main .img-container {
  border-radius: 8px !important;
}

.info-box-content-main img {
  width: 100px;
  height: 100px;
}

.info-box-content-main h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.info-box-content-main h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 20px;
}
.info-box-content-main p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.info-box-content-main p > span {
  color: #dda20c;
  cursor: pointer;
}
.info-box-content-footer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
}
.info-box-content-footer-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dda20c;
  border-radius: 0px 0px 0px 8px;
}
.info-box-content-footer-title span {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.info-box-content-footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background: #000000;
  border-radius: 0px 0px 8px 0px;
}
.info-box-content-footer-content .footer-el {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}
.info-box-content-footer-content .footer-el p {
  margin-bottom: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffe6a5;
}
.info-box-content-footer-content .footer-el span {
  background: #383838;
  border-top: 2px solid #dda20c;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: #ffc01f;
  padding: 5px 25px;
  margin-left: 5px;
}
</style>