<template>
  <div style="height: 100% !important">
    <center class="fullLoader" v-if="spinner">
      <Loader></Loader>
    </center>
    <div class="card cmnbr">
      <div class="card-header cardbdop cmnbr">
        <div class="row rwalg">
          <div class="col-md-12 timf padadj">
            {{ tourname }}
          </div>
          <!-- <div class="col-md-6">
            <span class="cardhd"> {{ t1predictedCount }} participants</span>
          </div>
          <div class="col-md-6">
            <span class="cardhd">{{ t2predictedCount }} participants</span>
          </div> -->
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <h5 class="card-title cardtit">{{ team1Name }}</h5>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <h5 class="card-title cardtit">{{ team2Name }}</h5>
          </div>
        </div>

        <div class="row rwalg">
          <div class="col-md-5">
            <img class="cardimg" :src="team1Flag" />
            <br />
            <span class="cardhd"> {{ t1predictedCount }} participants</span>
          </div>
          <div class="col-md-2">
            <img class="vsimg" :src="vs_logo" />
          </div>
          <div class="col-md-5">
            <img class="cardimg" :src="team2Flag" />
            <br />
            <span class="cardhd">{{ t2predictedCount }} participants</span>
          </div>
          <br />
        </div>
        <div class="row">
          <div class="col-md-6 centcls algtop divpadd">
            <div class="cardtxnbg" v-if="reward_amt_team1 != null">
              <span class="i-win">
                &nbsp;
                <font-awesome-icon
                  icon="fa-solid fa-trophy"
                  style="color: gold"
                />
              </span>
              <span class="wintxt">&nbsp;Win</span>
              <span class="wintxt2"
                >&nbsp;{{ reward_amt_team1.toFixed(2) }}
                {{ reward_syml_team1 }} </span
              >&nbsp;<img class="cardtnimg" :src="team1_reward_token_logo" />
            </div>
          </div>
          <div class="col-md-6 centcls algtop divpadd">
            <div class="cardtxnbg" v-if="reward_amt_team2 != null">
              <span class="i-win">
                &nbsp;
                <font-awesome-icon
                  icon="fa-solid fa-trophy"
                  style="color: gold"
                />
              </span>
              <span class="wintxt">&nbsp;Win</span>
              <span class="wintxt2"
                >&nbsp;{{ reward_amt_team2.toFixed(2) }}
                {{ reward_syml_team2 }} </span
              >&nbsp;<img class="cardtnimg" :src="team2_reward_token_logo" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 centcls">
            <button
              :disabled="team1_nft_contract_address == null"
              type="button"
              class="btn betbtn"
              @click="
                selectTeam(
                  matchID,
                  team1ID,
                  team1Name,
                  team1Flag,
                  team1_nft_contract_address,
                  reward_amt_team1,
                  reward_syml_team1,
                  team1_nftName,
                  team1_reward_token_logo
                )
              "
            >
              Bet {{ team1short }} Win
            </button>
          </div>
          <!-- <div class="col-md-2 "></div> -->
          <div class="col-md-6 centcls">
            <button
              :disabled="team2_nft_contract_address == null"
              type="button"
              class="btn betbtn"
              @click="
                selectTeam(
                  matchID,
                  team2ID,
                  team2Name,
                  team2Flag,
                  team2_nft_contract_address,
                  reward_amt_team2,
                  reward_syml_team2,
                  team2_nftName,
                  team2_reward_token_logo
                )
              "
            >
              Bet {{ team2short }} Win
            </button>
          </div>
        </div>
      </div>

      <div class="card-header cardbdop cmnbr">
        <div class="row">
          <div class="col-md-12 text-center">
            <!-- <Countdown :deadline="start_time" class="timf"></Countdown> -->
            <countdown :end-time="new Date(start_time).getTime()">
              <span class="timf" slot="process" slot-scope="{ timeObj }">{{
                `${timeObj.d}D : ${timeObj.h}H : ${timeObj.m}M : ${timeObj.s}S`
              }}</span>
              <span class="timf" slot="finish">LIVE</span>
            </countdown>
          </div>
        </div>
      </div>
      <BuyPopUp :open="isbpop" @close="isbpop = !isbpop"></BuyPopUp>
      <ConfirmationVue
        :open="conform"
        :img="timg"
        :team="team"
        :reward_amt="reward_amt"
        :token_symbol="reward_syml"
        :match_name="match_name"
        :date="start_time"
        :nftName="nftName"
        :team_reward_logo="team_reward_logo"
        @closed="close"
        @confirm="confirm"
      ></ConfirmationVue>
      <ErrorShow :msg="ermsg" :open="errop"></ErrorShow>
      <BetSuccessVue
        @closed="closeSuccess"
        :open="isSuccess"
        :tournament_name="nftsData.tournament_name"
        :team_name="nftsData.team_name"
        :team_image="nftsData.team_image"
        :available="nftsData.available"
        :used="nftsData.used"
      ></BetSuccessVue>
      <BetErrorVue
        @closed="closeError"
        :open="isError"
        :img="timg"
        :available="nftsData.available"
        :used="nftsData.used"
      ></BetErrorVue>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import BuyPopUp from "../components/BuyPopUp.vue";
import ErrorShow from "../components/ErrorShow.vue";
import BetSuccessVue from "./BetSuccess.vue";
import BetErrorVue from "./BetError.vue";
import { APIService } from "../apiservice.js";
import Swal from "sweetalert2";
import ConfirmationVue from "./confirmation.vue";
import Loader from "../components/loader.vue";
import { NFTContract } from "@/helpers/nftContract";
import { connectMetamask, switchNetwork } from "../metamask";

export default {
  components: {
    BuyPopUp,
    ConfirmationVue,
    Loader,
    ErrorShow,
    BetSuccessVue,
    BetErrorVue,
  },
  props: {
    date: String,
    t1predictedCount: Number,
    t2predictedCount: Number,
    team1Flag: String,
    team2Flag: String,
    team1Name: String,
    team2Name: String,
    team1short: String,
    team2short: String,
    team1ID: Number,
    team2ID: Number,
    matchID: Number,
    reward_amt_team1: Number,
    reward_syml_team1: String,
    reward_amt_team2: Number,
    reward_syml_team2: String,
    match_name: String,
    team1_reward_token_logo: String,
    team2_reward_token_logo: String,
    vs_logo: String,
    team1_nftName: String,
    team2_nftName: String,
    start_time: {
      type: String,
      require: true,
    },
    team1_nft_contract_address: String,
    team2_nft_contract_address: String,
    network: Object,
    tourname: String,
  },
  data() {
    return {
      reward_amt: null,
      reward_syml: null,
      errop: false,
      ermsg: null,
      isbpop: false,
      activeColor: "red",
      conform: false,
      isSuccess: false,
      isError: false,
      spinner: false,
      timg: null,
      team: null,
      nftName: null,
      team_reward_logo: null,
      nftsData: {
        tournament_name: null,
        team_name: null,
        team_image: null,
        available: null,
        used: null,
      },
    };
  },
  methods: {
    // moment: function (date) {
    //   console.log(moment(date).format("YYYY-MM-DD hh:mm:ss"));
    //   return moment(date).format("YYYY-MM-DD hh:mm:ss");
    // },

    closeSuccess() {
      this.isSuccess = false;
      // setTimeout(() => {
      window.location.reload();
      // }, 3000);
    },
    closeError() {
      this.isError = false;
    },
    close() {
      this.conform = false;
      // this.isSuccess = true;
    },
    async confirm() {
      this.errop = false;
      this.conform = false;
      this.spinner = true;

      let switchStatus = await switchNetwork({
        chainId: this.network.network_chain_id,
        chainName: this.network.name,
        rpcUrls: [this.network.network_rpc_url],
        blockExplorerUrls: [this.network.network_explorer],
      });
      // console.log("Switch network", switchStatus);
      if (switchStatus) {
        const isMetaMask = await connectMetamask();
        // console.log("isMetaMask", isMetaMask);
        if (isMetaMask) {
          let transactionHash = await this.initPayment(
            this.contoAddress,
            this.conmatchID
          );
          if (transactionHash.status) {
            // console.log("data rec", transactionHash);

            let data = {
              matchId: this.conmatchID,
              selectedTeamId: this.conselectedTeamId,
              txnHash: transactionHash.txHash,
              toAddress: this.contoAddress,
            };

            let respon = await APIService.betNFT(data);

            // console.log("return respon", respon);

            if (respon.status == true) {
              // console.log("this.contoAddress", this.contoAddress);
              // let getNftInfo = await NFTContract.getNftInfo(this.contoAddress);
              // console.log("getNftInfo", getNftInfo);
              this.nftsData = respon.data[0];
              this.spinner = false;
              this.isSuccess = true;
              // Swal.fire({
              //   position: "center",
              //   icon: "success",
              //   title: respon.message,
              //   showConfirmButton: false,
              //   timer: 5000,
              // });

              // setTimeout(() => {
              //   window.location.reload();
              // }, 5000);
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: respon.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          } else {
            if (transactionHash.txHash == "Please buy NFT before Predicting") {
              this.spinner = false;
              this.isbpop = true;
            } else {
              this.spinner = false;
              this.errop = true;
              this.ermsg = transactionHash.txHash;
            }
          }
        } else {
          this.spinner = false;
          this.errop = true;
          this.ermsg = "Please Connect to your Metamask Wallet";
        }
      } else {
        this.spinner = false;
        this.errop = true;
        this.ermsg = "Please switch network";
        return false;
      }
    },

    async initPayment(CA, matchID) {
      try {
        // console.log("payment intiated", CA, matchID);
        let txHash = await NFTContract.bet(CA, matchID);
        if (txHash) {
          // console.log("result", txHash);
          let data = {
            status: true,
            txHash: txHash,
          };
          return data;
        } else {
          // console.error("Could not buy NFT", txHash);
          let txHash = "Could not buy NFT";
          let data = {
            status: false,
            txHash: txHash,
          };

          return data;
        }
      } catch (e) {
        console.error("Error while buy", e);
        let errMsg =
          e.data && e.data.message
            ? e.data.message.split(":")[1]
            : e.error && e.error.data && e.error.data.message
            ? e.error.data.message
            : e;
        console.error("Error message from contract", errMsg);
        if (errMsg.code == 4001) {
          let data = {
            status: false,
            txHash: "Please accept the metamask request to proceed",
          };
          return data;
        } else if (errMsg.code == -32603) {
          let data = {
            status: false,
            txHash: "Please make sure your internet connection is stabled",
          };
          return data;
        } else {
          let data = {
            status: false,
            txHash: errMsg,
          };
          return data;
        }
      }
    },

    async selectTeam(
      matchID,
      selecedTeamID,
      teamName,
      teamFlag,
      CA,
      reward_amt,
      reward_syml,
      nftName,
      tokenLogo
    ) {

       console.log("teamName", teamName);

      this.errop = false;

      this.spinner = true;
      this.isbpop = false;
      const isMetaMask = await connectMetamask();
      // console.log("isMetaMask", isMetaMask);
      if (isMetaMask) {
        console.log(
          "this.network.network_chain_id",
          this.network.network_chain_id
        );
        let switchStatus = await switchNetwork({
          chainId: this.network.network_chain_id,
          chainName: this.network.name,
          rpcUrls: [this.network.network_rpc_url],
          blockExplorerUrls: [this.network.network_explorer],
        });
        console.log("switchStatus", switchStatus);

        let getNftInfo;
        let checkUserNFT;
        if (switchStatus) {
          getNftInfo = await NFTContract.getNftInfo(CA);
          checkUserNFT = await NFTContract.getAlreadyUsed(CA, matchID);
          console.log("checkUserNFT", checkUserNFT);
        } else {
          this.spinner = false;
          this.errop = true;
          this.ermsg = "Please switch network";
          return false;
        }
        // console.log("checkUserNFT", checkUserNFT);
        // await APIService.getcheckexistsNFT(
        //   selecedTeamID,
        //   matchID
        // );
        // console.log("checkUserNFT", checkUserNFT);
        this.nftsData = getNftInfo;
        // this.nftsData.tournament_name = null
        this.spinner = false;
        if (!checkUserNFT) {
          this.conform = true;
          this.timg = teamFlag;
          this.team = teamName;
          this.reward_amt = reward_amt;
          this.reward_syml = reward_syml;
          this.conmatchID = matchID;
          this.conselectedTeamId = selecedTeamID;
          this.contoAddress = CA;
          this.nftName = nftName;
          this.team_reward_logo = tokenLogo;
          console.log("this.nftName ", this.nftName);
        } else {
          // this.errop = true;
          // this.ermsg =
          // "You already betted for this team. Please check joined contest";
          this.isError = true;
          // Swal.fire({
          //   position: "center",
          //   icon: "info",
          //   title:
          //     "You already betted for this team. Please check joined contest",
          //   showConfirmButton: true,
          //   confirmButtonText: "ok",
          //   timer: 5000,
          // });
        }
      } else {
        this.spinner = false;
        this.errop = true;
        this.ermsg = "Please Connect to your Metamask Wallet";
      }
    },
  },
  created() {},
};
</script>
<style>
.padadj {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.divpadd {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.algtop {
  margin-top: 10px;
}
.algpad {
  padding: 2px 2px 2px 2px !important;
}
.centcls {
  text-align: center;
}
.wintxt {
  font-family: "Roboto" !important;
  font-size: 12px;
  color: white;
  font-weight: 400;
}
.wintxt2 {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  color: #ffc737;
}
.cardtxnbg {
  background: radial-gradient(black, transparent);
  /* border-radius: 15px; */
}
.cardtnimg {
  width: 12px !important;
  height: 12px !important;
  border-radius: 10px !important;
}
.vsimg {
  /* margin-left: -15px; */
  width: 37px;
}

.marginpad {
  margin-left: -42px;
}

.vuejs-countdown li:after {
  content: ":";
  position: absolute;
  top: 6px !important;
  right: -11px !important;
  font-size: 20px !important;
}

.vuejs-countdown .digit {
  font-size: 14px !important;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}

.timf {
  font-family: Ubuntu !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center;

  color: #ffcc69;
}
</style>
<style scoped>
.card-header {
  padding: 0.25rem 0.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.cmnbr {
  border-radius: 8px;
}

.pad {
  padding-left: 3px;
  padding-right: 3px;
}

.cardhd {
  font-family: Ubuntu !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center !important;
  color: #ffcc69;
}

.cardwt {
  font-family: Ubuntu !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  text-align: center !important;

  color: #daf0ff;
}

.rwalg {
  text-align: center !important;
}

.cardimg {
  width: 54px;
  height: 54px;
  border-radius: 100px;
}

.cardtit {
  color: white;
  font-family: Ubuntu !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}

.cardbdop {
  background-color: rgba(245, 245, 245, 0.4) !important;
  border-radius: 8px !important;
}

.betbtn {
  border-radius: 30px;
  margin-top: 10px;
  background-color: white;
  color: #08058a;
  font-weight: 700;
  font-size: 10px;
  font-family: "Roboto" !important;
  text-align: center;
  width: inherit;
}
</style>
<style>
/* @font-face {
  font-family: "" Roboto "";
  src: local("" Roboto ""),
    url("../../assets/font/"Roboto"/RobotoCondensed-Regular.ttf")
      format("truetype");
} */
</style>