import { ethers } from "ethers";
import nftAbi from "../assets/abi/nft.json"
import { erc20 } from "./erc20";
import { utills } from "./utill";
let nftAddress = "0xbE5e06049CBa080156FE6023F49f6072F34C2A95";
// let provider;
// let signer;
// if (window.ethereum !== undefined) {
//     provider = new ethers.providers.Web3Provider(window.ethereum);
//     signer = provider.getSigner();
// }

export const NFTContract = {
    async bet(nftAddress, matchId) {
        try {
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            let signer = provider.getSigner();
            let estimate;
            // console.log("user address", await signer.getAddress())
            let nft = new ethers.Contract(nftAddress, nftAbi, signer);
            let nftBalance = await nft.balanceOf(await signer.getAddress());
            if (nftBalance > 0) {
                try {
                    estimate = await nft.estimateGas.increaseUsableCount(matchId);
                } catch (e) {
                    console.log("Error while estimete gas create NFT", e);
                    throw (e);
                }
                let res = await nft.increaseUsableCount(matchId);
                let receipt = await res.wait();
                // console.log("created contract", receipt);
                return receipt.transactionHash;
            } else {
                throw ("Please buy NFT before Predicting");
            }
        } catch (e) {
            console.log("Error while execute transaction", e);
            throw (e);
        }
    },
    async balance(nftAddress) {
        try {
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            let signer = provider.getSigner();
            let nft = new ethers.Contract(nftAddress, nftAbi, signer);
            let nftCount = await nft.balanceOf(await signer.getAddress());
            return nftCount;
        }
        catch (e) {
            console.log("Error while get allowance", e);
            throw (e);
        }
    },
    async getTokenId(nftAddress) {
        try {
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            let signer = provider.getSigner();
            let nft = new ethers.Contract(nftAddress, nftAbi, signer);
            let tokenId = await nft.getTokenIdByOwner();
            return tokenId;
        }
        catch (e) {
            console.log("Error while get allowance", e);
            throw (e);
        }
    },
    // async getNftInfo() {
    //     try {
    //         let provider = new ethers.providers.Web3Provider(window.ethereum);
    //         let signer = provider.getSigner();
    //         let nft = new ethers.Contract(nftAddress, nftAbi, signer);
    //         console.log("nft", nft);
    //         let tokenId = await nft.getTokenIdByOwner();
    //         console.log("tokenId", tokenId);
    //         if (tokenId > 0) {
    //             let used = await nft.count(tokenId);
    //             let maxUsage = await nft.maxUsage();
    //             return {
    //                 used: used,
    //                 available: maxUsage - used
    //             }
    //         } else {
    //             throw ("No nft availble");
    //         }
    //     }
    //     catch (e) {
    //         console.log("Error while get allowance", e);
    //         throw (e);
    //     }
    // },
    async getNftInfo(nftAddress) {
        try {
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            let signer = provider.getSigner();
            let nft = new ethers.Contract(nftAddress, nftAbi, signer);

            let tokenId = utills.format(await nft.getTokenIdByOwner(), 0);
            if (tokenId > 0) {
                let used = utills.format(await nft.count(tokenId), 0);
                // console.log("used", used);
                let maxUsage = utills.format(await nft.maxUsage(), 0);
                // console.log("max usage", maxUsage)
                return {
                    used: used,
                    available: maxUsage - used,
                    maxUsage:maxUsage
                }
            } else {
                return {
                    used: null,
                    available: null,
                    maxUsage:null
                };
                // throw ("No nft availble");
            }
        }
        catch (e) {
            console.log("Error while get nft info", e);
            throw (e);
        }
    },

    async getAlreadyUsed(nftAddress, matchId) {
        try {
            let provider = new ethers.providers.Web3Provider(window.ethereum);
            let signer = provider.getSigner();
            let nft = new ethers.Contract(nftAddress, nftAbi, signer);
            let tokenId = utills.format(await nft.getTokenIdByOwner(), 0);
            let matchStatus = await nft._matches(matchId, tokenId);
            // console.log("matchStatus", matchStatus);
            return matchStatus;
        }
        catch (e) {
            console.log("Error while get usage info", e);
            throw (e);
        }
    },
}