<template>
  <transition name="fade">
    <div>
      <div class="bgcn" v-show="open">
        <div class="popup-container">
          <div class="header">
            <span class="poptitle">Buy NFT</span>
            <div class="close" @click="close()">x</div>
          </div>
          <div class="content">
            <img src="../assets/disagree.png" alt="" />
            <span class="alert">Please Buy NFT before Predicting!</span>
            <button class="payment" @click="BuyNFT()">BUY</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script >
export default {
  name: "BuyPopUp",
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    BuyNFT() {
      // console.log("explorerNFT");
      this.$router.push({ name: "explorerNFT" });
    },
    close() {
      this.open = false;
    },
  },
};
</script>

<style scoped >
.bgcn {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1032;

  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert {
  margin-left: 86px;
  font-family: "Roboto" !important;
  font-weight: 700;
  font-size: 24px;
  color: #1f0e88;
  padding: 0;
  height: 28px;
}
img {
  width: 64px;
  height: 64px;
  margin-top: 24px;
  margin-left: 218px;
}
.popup-container {
  display: grid;
  width: 500px;
  height: 300px;
  background: #ffffff;
  border-radius: 8px;
}
.header {
  width: 500px;
  height: 62px;
  background: #c4c4c4;
  border-radius: 8px 8px 0px 0px;
  display: inline-block;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 11px 24px 0px 24px;
  font-family: "Roboto" !important;
  font-weight: 700;
}
.content {
  display: grid;
  width: 500px;
  height: 238px;
  row-gap: 0px;
}
.payment {
  width: 150px;
  height: 40px;
  background: #001666;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
  border-radius: 30px;
  color: white;
  margin-left: 176px;
  cursor: pointer;
  text-align: center;
  padding: 7px;
  font-family: "Roboto" !important;
  font-weight: 700;
}
.close {
  width: 40px;
  height: 40px;
  color: #01425d;
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid #01425d;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  font-family: "Roboto" !important;
}
.poptitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  width: 95px;
  height: 33px;
  color: #01425e;
  font-family: "Roboto" !important;
  font-weight: 700;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>