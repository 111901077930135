
let user = JSON.parse(localStorage.getItem("Authdata"));

function authHeader() {
   
    if (user == null) {
        user = JSON.parse(localStorage.getItem("Authdata"));
        // console.log("user",user)
    }
    return {
        headers: {
            Authorization: user ? "Bearer " + user.tokenAddress : ""
        }
    }
}
module.exports = { authHeader };