import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import usersNFT from '../views/usersNFT.vue'
import myNftDetils from "../views/myNftDetails.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/buyNFT-loadmore',
    name: 'BuyNFT',

    component: () => import(/* webpackChunkName: "BuyNFT" */ '../views/buyNFT-loadmore.vue')
  },
  {
    path: '/ongoing',
    name: 'ongoing',

    component: () => import(/* webpackChunkName: "BuyNFT" */ '../views/ongoingmatch.vue')
  },
  {
    path: '/completed',
    name: 'completed',

    component: () => import(/* webpackChunkName: "BuyNFT" */ '../views/completed.vue')
  },
  {
    path: '/usersNFT',
    name: 'usersNFT',

    component: () => import(/* webpackChunkName: "BuyNFT" */ '../views/usersNFT.vue')
  },
  {
    path: '/btn',
    name: 'btn',

    component: () => import(/* webpackChunkName: "BuyNFT" */ '../views/btn.vue')
  },
  {
    path: '/explorerNFT',
    name: 'explorerNFT',
    component: () => import(/* webpackChunkName: "BuyNFT" */ '../views/explorerNFT.vue')
  },
  {
    path: '/myNFT',
    name: 'myNFT',
    component: () => import(/* webpackChunkName: "BuyNFT" */ '../views/myNFT.vue')
  },
  {
    path: '/joinnedContest',
    name: 'joinnedContest',
    component: () => import(/* webpackChunkName: "joinnedContest" */ '../views/joinnedContest.vue')
  },
  {
    path: '/filtergames/:id',
    name: 'filtergames',
    component: () => import(/* webpackChunkName: "joinnedContest" */ '../views/filtergames.vue')
  },
  {
    path: '/myNftDetils/:id',
    name: 'myNftDetils',
    component: myNftDetils
  },
  {
    path: '/transaction_history',
    name: 'transaction_history',
    component: () => import(/* webpackChunkName: "joinnedContest" */ '../views/transaction_history.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "joinnedContest" */ '../views/faq.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "joinnedContest" */ '../views/contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
