import { ethers } from "ethers";
import ercAbi from "../assets/abi/erc20Abi.json"
import { utills } from "./utill";

export const erc20 = {
    async approve(signer, approveAddress, amount, tokenCA) {
        let erc20C = new ethers.Contract(tokenCA, ercAbi, signer);
        let tx = await erc20C.approve(approveAddress, amount);
        let receipt = await tx.wait();
        return receipt;
    },
    async allowance(signer, address, tokenCA, decimal) {
        try {
            let erc20C = new ethers.Contract(tokenCA, ercAbi, signer);
            let allowanceWei = await erc20C.allowance(await signer.getAddress(), address);
            let allowance = utills.format(allowanceWei, decimal);
            return allowance;
        }
        catch (e) {
            console.log("Error while get allowance", e);
            throw (e);
        }
    },

    async balance(signer, tokenCA, decimal) {
        try {
            let erc20C = new ethers.Contract(tokenCA, ercAbi, signer);
            let allowanceWei = await erc20C.balanceOf(await signer.getAddress());
            let allowance = utills.format(allowanceWei, decimal);
            return parseFloat(allowance);
        }
        catch (e) {
            console.log("Error while get allowance", e);
            throw (e);
        }
    }
}