<template>
  <transition name="fade">
    <div class="bgcn" v-show="open">
      <transition name="drop-in">
        <div class="main-container">
          <div class="header">
            <span class="title">Confirmation </span>
            <button @click="close()" class="btncls">X</button>
          </div>
          <div class="info-box">
            <div class="infobox-content">
              <div class="right-content">
                <div>
                  <img :src="img" alt="" />
                </div>
                <div class="match-info">
                  <h3 class="team-title">{{ team }}</h3>
                  <span class="team-battle"> {{ match_name }}</span>
                  <span class="match-date"
                    ><p>{{ momentDay(date) }}</p>
                    <div class="info-dot"></div>
                    <p>{{ momentTime(date) }}</p>
                    <div class="info-dot"></div>
                    <p>{{ momentDate(date) }}</p>
                  </span>
                  <br />
                  <h3 class="team-title">NFT Name: {{ nftName }}</h3>
                </div>
              </div>
              <div class="left-content">
                <h4>NFT Usage :</h4>
                <div class="nft-usage">
                  <p>+1</p>
                </div>
              </div>
            </div>
            <div class="infobox-footer">
              <div class="infobox-footer-left">
                <h6>Reward Prize :</h6>
                <span class="footer-reward"
                  ><img :src="team_reward_logo" alt="" />
                  <p>
                    {{ reward_amt ? reward_amt.toFixed(2) : "" }}
                    {{ token_symbol }}
                  </p>
                </span>
              </div>
              <button @click="onClickButton()" class="infobox-footer-right">
                <img src="../assets/dark-vector.png" alt="" />
                <span>Confirm Bet</span>
              </button>
            </div>
            <!-- <span class="alert"
              >You have chosen <span class="tname">{{ team }}</span> as your
              winner.</span
            > -->
            <!-- <span class="alert"
              >Winning prize <span class="tname">{{ reward_amt }} {{token_symbol}}</span>
              </span -->
            <!-- > -->
            <!-- <span class="alert">Click on confirm button to predict.</span>
            <button class="confmb" @click="onClickButton()">Confirm</button> -->
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import moment from "moment";
export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    team: String,
    img: String,
    reward_amt: Number,
    token_symbol: String,
    match_name: String,
    date: String,
    nftName: String,
    team_reward_logo: String,
  },
  data() {
    return {};
  },
  methods: {
    moment: function (date) {
      console.log(moment(date).format("YYYY-MM-DD hh:mm:ss"));
      return moment(date).format("YYYY-MM-DD hh:mm:ss");
    },
    momentDay: function (date) {
      // console.log(moment(date).format("YYYY-MM-DD hh:mm:ss"));
      return moment(date).format("dddd");
    },
    momentTime: function (date) {
      // console.log(moment(date).format("YYYY-MM-DD hh:mm:ss"));
      return moment(date).format("LT");
    },
    momentDate: function (date) {
      return moment(date).format("Do MMM YY");
    },
    onClickButton() {
      this.$emit("confirm");
    },
    close() {
      this.$emit("closed", this.open);
    },
  },
};
</script>

<style scoped>
.bgcn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1032;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tname {
  color: #dda20c;
  font-size: 25px;
}
.alert {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  color: #ffffff;
}
.main-container {
  background-color: black;
  /* display: grid; */
  width: 600px;
  /* height: 300px; */
  border-radius: 15px;
  font-family: "Roboto";
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 600px;
  height: 48px;
  justify-content: space-between;
  background-color: #1c1c1c;
  border-radius: 15px 15px 0 0;
  padding: 0px;
}
.title {
  margin-top: 5px;
  font-family: Roboto;
  font-size: 26px;
  color: #dda20c;
  font-weight: 700;
  margin-left: 14px;
}
/* .about {
        display: grid;
        padding: 10px;
        margin:30px;
    } */

.btncls {
  background-color: transparent;
  border: 3px solid #dda20c;
  color: #dda20c;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
  font-weight: bolder;
  margin: 0px;
  cursor: pointer;
  border-radius: 10px;
  width: 25px;
  height: 25px;
  margin: 10px;
}

h2 {
  color: #dda20c;
  font-size: 30px;
}
.name {
  color: #ffed50;
  font-size: 20px;
  text-align: center;
}

.info-box {
  /* display: grid; */
  width: 600px;
  height: max-content;
  /* border-radius: 0 0 15px 15px; */
  /* justify-items: center; */
}
.confmb {
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 180px;
  height: 36px;
  background-color: black;
  color: #dda20c;
  border-radius: 30px;
  border: 3px solid #dda20c;
  box-shadow: 2px 4px 4px rgb(255 255 255 / 25%);
  border-radius: 16px;
  padding: 0px;
  font-family: "Ubuntu" !important;
  font-size: 18px;
  font-weight: 700;

  margin-bottom: 13px;
}
.imhld {
  display: grid;
  align-content: center;
}

img {
  width: 100px;
  border-radius: 3px;
  margin-top: 10px;
}
p {
  color: white;
  font-size: 20px;
  padding-left: 40px;
  margin: 0px;
  font-size: 16px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-in;
}

.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translate(0, -100px);
}
.infobox-content {
  display: grid;
  grid-template-columns: 5fr 2fr;
  padding: 30px;
  background-color: #303030;
}
.infobox-content .right-content {
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  /* justify-content: center; */
}
.infobox-content .right-content .match-info {
  /* width: 60%; */
}
.infobox-content .right-content img {
  width: 100%;
  /* height: 80px; */
}
.match-info,
.left-content {
  display: flex;
  align-items: flex-start;
  /* justify-content: start; */
  flex-direction: column;
}
.match-info {
  margin-left: 15px;
}
.match-info .team-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #dda20c;
}
.match-info .team-battle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
.match-info .match-date {
  display: flex;
  justify-content: start;
  align-items: center;
}
.match-info .match-date p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding-left: 0px;
}
.info-dot {
  margin: 0 10px;
  width: 5px;
  height: 5px;
  background-color: #b8b8b8;
  border-radius: 50%;
}
.left-content {
  border-left: 1px solid #b8b8b8;
  padding-left: 20px;
}
.left-content h4 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */

  color: #dda20c;
}
.nft-usage {
  background: #000000;
  border-top: 2px solid #dda20c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 30px; */
}
.nft-usage p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  padding-left: 0px;
  padding: 0px 25px;
  /* text-align: center; */
  color: #ffc01f;
}
.infobox-footer {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.infobox-footer-left {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  padding: 20px 10px;
  border-bottom-left-radius: 8px;
}
.infobox-footer-left > h6 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  /* line-height: 28px; */
  color: #ffffff;
}
.footer-reward {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
}
.footer-reward img {
  margin-top: 0px;
  width: 30px;
  height: 30px;
}
.footer-reward p {
  padding-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffc01f;
}
.infobox-footer-right {
  background: #dda20c;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border: none;
  border-bottom-right-radius: 8px;
}
.infobox-footer-right img {
  margin-top: 0px;

  width: 30px;
  height: 30px;
}
.infobox-footer-right span {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-left: 5px;
}
</style>