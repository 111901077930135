<template>
  <div>
    <!-- Sidebar -->
    <nav
      id="sidebar"
      class="vue-bootstrap-sidebar default-theme"
      :class="[theme] && [show ? 'sidebar-visible' : 'sidebar-hidden']"
      :click-to-close="true"
    >
      <!-- eslint-disable vue/no-v-html -->

      <img
        class="navImg"
        src="./../../public/Rage_bg.png"
      />

      <div class="sidebar-header headercls header-main" v-html="header" />
      <!-- eslint-enable -->
      <b-list-group class="items-wrapper navpadding">
        <template v-for="(link, index) in links">
          <template v-if="link.href !== undefined">
            <b-list-group-item :key="index">
              <div class="sidebar-header headercls" v-html="link.subheader" />
              <b-button
                block
                :to="link.href"
                variant="red"
                class="btn-primary-outline sidebar-menu-item menubtn"
                :squared="true"
                v-b-tooltip.hover
                :title="getTooltipText(link.name)"
              >
                <div class="fa-icon icsiz">
                  <component
                    v-if="link.faIcon"
                    :is="'font-awesome-icon'"
                    :icon="link.faIcon"
                  />
                </div>
                <div class="link-name navText">
                  {{ link.name }}
                </div>
              </b-button>
              <hr class="hrstyle" />
            </b-list-group-item>
          </template>

          <template v-else>
            <b-list-group-item :key="index">
              <div class="sidebar-header headercls" v-html="link.subheader" />
              <button
                v-b-toggle="`accordion-${index + 10}`"
                block
                href="#"
                class="sidebar-menu-item my-2 dropdown-toggle"
                v-b-tooltip.hover
                :title="getTooltipText(link.name)"
              >
                <div class="fa-icon">
                  <!-- <component
                    v-if="link.faIcon"
                    :is="'font-awesome-icon'"
                    :icon="link.faIcon"
                  /> -->
                  <img
                    src="./../../assets/image/football.png"
                    style="width: 18px"
                  />
                </div>
                <div class="link-name navText" aria-current="Ongoing">
                  {{ link.name }}
                </div>
              </button>
            </b-list-group-item>

            <b-collapse
              :id="`accordion-${index + 10}`"
              :key="index + 10"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(child, idx) in link.children"
                  :key="idx"
                >
                  <button
                    block
                    :class="`${child.id}-id sidebar-menu-item my-2 child-level-1`"
                    :to="child.href"
                    v-b-tooltip.hover
                    :title="getTooltipText(child.name)"
                  >
                    <div class="fa-icon">
                      <!-- <component
                        v-if="child.faIcon"
                        :is="'font-awesome-icon'"
                        :icon="child.faIcon"
                      /> -->
                    </div>
                    <a
                      class="link-name navText"
                      @click="getId(child.id, child.name, link.name)"
                    >
                      {{
                        child.name.length > 20
                          ? child.name.slice(0, 15) + "...."
                          : child.name
                      }}
                    </a>
                  </button>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </template>
        </template>
      </b-list-group>
      <!--/ .items-wrapper -->
      <HamburgerButton
        v-if="closeButton"
        id="sidebarButton"
        class="sidebar-button"
        :is-hamburger="!initialShow"
        :class="[show ? 'visible' : 'hidden']"
        @click="onButtonClick"
      />
    </nav>
    <div id="navbar" :class="[show ? 'sidebar' : 'no-sidebar']">
      <slot name="navbar" />
    </div>
    <!-- Hamburger Menu -->
    <div id="content" :class="[show ? 'sidebar' : 'no-sidebar']">
      <slot name="content" />
    </div>
  </div>
</template>
<script>
import HamburgerButton from "@jurajkavka/vue-hamburger-button";
import { library } from "@fortawesome/fontawesome-svg-core";

export default {
  name: "BootstrapSidebar",
  components: {
    HamburgerButton,
    library,
  },

  props: {
    links: {
      type: Array,
      default: null,
    },
    initialShow: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: "default-theme",
    },
    header: {
      type: String,
      default: "Sidebar",
    },
    subheader: {
      type: String,
      default: "Sidebar",
    },

    closeButton: {
      type: Boolean,
      default: false,
    },
    hideToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: this.initialShow,
    };
  },

  methods: {
    getTooltipText(text) {
      return text.length > 20 ? text : "";
    },
    getId(Id, name, sportsName) {
      let allEl = document.getElementsByClassName("child-level-1");
      for (var i = 0; i < allEl.length; i++) {
        allEl[i].classList.remove("active");
      }
      let el = document.getElementsByClassName(`${Id}-id`);
      el[0].classList.add(`active`);
      this.$router.push({
        name: "filtergames",
        params: { id: Id, leagueName: name, sports_Name: sportsName },
      });
    },
    onButtonClick() {
      this.show = !this.show;
      this.$emit("sidebarChanged", this.show);
    },
  },
  created() {
    console.log("header", this.header);
  },
};
</script>
<style>
.child-level-1 {
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  /* padding: 12px; */
}
.child-level-1 a {
  color: #fff !important;
}
.child-level-1 a:hover {
  text-decoration: none;
}
.child-level-1.active a {
  color: #ffda2b !important;
}
#sidebar::-webkit-scrollbar {
  width: 6px;
}
/* #sidebar::-webkit-scrollbar-track {
  background: #ffda2b;
} */
#sidebar::-webkit-scrollbar-thumb {
  background: #ffda2b;
  border-radius: 10px;
}
#sidebar::-webkit-scrollbar-thumb:hover {
  /* background: #fff; */
  width: 5px !important;
  border: 130px solid #ffda2b !important;
  border-radius: 9px;
  background-clip: content-box !important;
}
</style>
<style scoped>
.header-main {
  margin-left: 10px;
  margin-top: 15px;
}
.default-theme .list-group-item .sidebar-menu-item {
  background-color: linear-gradient(315deg, #ffcfdf 0%, #b0f3f1 74%);
  color: white;
  border: none;
  padding-left: 15px !important;
}
/* .icsiz{
  font-size: x-large;
} */
.hrstyle {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important;
}
.navImg {
  height: 80px;
  margin-left: 49px;
}
.navText {
  font-size: 14px;
  font-weight: 500;
  font-family: Ubuntu !important;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
    url("../../assets/font/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
}
.navpadding {
  padding: 10px;
}
.child-level-1:active,
.child-level-1:focus {
  color: #ffda2b !important;
}

.menubtn {
  /* background-color: #b0f3f1 ;
            background-image: linear-gradient(315deg, #b0f3f1 20%, #b0f3f1 70%); */
  background-color: transparent;
  border-color: #ccc;

  /* background-color: transparent;
    border-color: transparent;
    box-shadow: none; */
}
.list-group-item {
  background-color: transparent !important;
}
</style>
<style lang="scss">
@import "node_modules/@jurajkavka/vue-hamburger-button/src/scss/default-theme.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
@import "../assets/sidebar-scss/default-theme";
</style>