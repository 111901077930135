<template>
  <div>
    <!-- <div class="head1">Cricket</div>
    <div class="head2">Indian Premier League 2022</div> -->
    <div class="row">
      <div class="head3 col-md-6">Upcoming Matches</div>
      <div class="head3 col-md-6" v-if="isClear">
        <button class="aplybtn" @click="refresh()">
          <font-awesome-icon icon="fa-solid fa-arrows-rotate" /> Clear
        </button>
      </div>
    </div>
    <div class="row leftAlg">
      <div class="col-md-3">
        <Vselect
          :value="defaultValue"
          :options="networks"
          labelTitle="Choose Network"
          @input="onSelecetNetwork"
        ></Vselect>
      </div>
      <div class="col-md-3">
        <Vselect
          :options="sports"
          :isReset="isRestAble"
          labelTitle="Choose Sport"
          aria-rowspan=""
          @input="onSelectSport"
        ></Vselect>
      </div>
      <div class="col-md-3">
        <Vselect
          :options="tournament"
          :isReset="isRestAble"
          labelTitle="Choose Tournament"
          @input="onSelecttrnmt"
          :disabled="istournament"
        ></Vselect>
      </div>
      <div class="col-md-3">
        <Vselect
          :options="teams"
          :isReset="isRestAble"
          labelTitle="Choose Team"
          @input="onSelectteam"
          :disabled="ismatch"
        ></Vselect>
      </div>
    </div>
    <br />
    <div
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="currentPage == totalPages"
      infinite-scroll-distance="10"
    >
      <div class="fullLoader" v-if="spinner">
        <Loader></Loader>
      </div>

      <div class="row cardpad cardbd divclrs" v-else>
        <div
          v-for="(match, index) in upcomings"
          :key="index"
          :id="index"
          class="col-xl-4 col-md-6"
          style="padding-bottom: inherit"
        >
          <CardDS
            class="card1"
            :date="match.date"
            :t1predictedCount="match.team1_participant_count"
            :t2predictedCount="match.team2_participant_count"
            :team1Flag="match.team1_logo"
            :team2Flag="match.team2_logo"
            :team1Name="match.team1_name"
            :team2Name="match.team2_name"
            :team1short="match.team1_short_name"
            :team2short="match.team2_short_name"
            :start_time="match.match_start_time"
            :matchID="match.match_id"
            :team1ID="match.team1_id"
            :team2ID="match.team2_id"
            :team1_nft_contract_address="match.team1_nft_contract_address"
            :team2_nft_contract_address="match.team2_nft_contract_address"
            :reward_amt_team1="match.team1_reward_token"
            :reward_syml_team1="match.team1_reward_token_symbol"
            :reward_amt_team2="match.team2_reward_token"
            :reward_syml_team2="match.team2_reward_token_symbol"
            :match_name="match.match_name"
            :vs_logo="match.vs_logo"
            :team1_nftName="match.team1_nft_name"
            :team2_nftName="match.team2_nft_name"
            :network="{
              name: match.network_name,
              chain_id: match.network_id,
              network_chain_id: match.network_chainid,
              network_explorer: match.network_explorer,
              network_rpc_url: match.network_rpc,
            }"
            :team1_reward_token_logo="match.team1_reward_token_logo"
            :team2_reward_token_logo="match.team2_reward_token_logo"
            :tourname="match.tour_name"
          ></CardDS>
        </div>

        <div v-if="errdiv" class="errmsg loadercss">
          <h5>No Matches Available At The Moment. Stay Tuned!</h5>
        </div>
      </div>
      <div v-if="bottomSpinner">
        <Loader></Loader>
      </div>
    </div>
    <ErrorShow :msg="ermsg" :open="errop"></ErrorShow>
  </div>

  <!-- <div class="alignLeftdiv" v-if="!spinner">
      <button
        type="button"
        class="btn btn-primary"
        @click="companiesVisible += step"
        v-if="companiesVisible < upcomings.length"
      >
        Load More
      </button>
    </div> -->
</template>
<script>
import CardDS from "../components/card.vue";
import { APIService } from "../apiservice.js";
import Loader from "../components/loader.vue";
import Vselect from "../components/v-select.vue";
import ErrorShow from "../components/ErrorShow.vue";
import { connectMetamask, switchNetwork } from "../metamask";
export default {
  components: {
    CardDS,
    Loader,
    Vselect,
    ErrorShow,
  },
  data() {
    return {
      errdiv: false,
      spinner: false,
      bottomSpinner: false,
      upcomings: [],
      companiesVisible: 10,
      step: 10,
      totalPages: 0,
      currentPage: 1,
      sports: [],
      tournament: [],
      teams: [],
      istournament: true,
      ismatch: true,
      isClear: false,
      wait: false,
      errop: false,
      ermsg: null,
      networks: [],
      defaultValue: null,
      isReset: false,
    };
  },
  computed: {
    isRestAble() {
      return this.isReset;
    },
  },
  methods: {
    refresh() {
      window.location.reload();
    },
    async onSelecetNetwork(networkId, option, oldValue) {
      console.log("one", option.name);
      // console.log("one enteree");
      this.isReset = true;
      // console.log("thisResedrtredsd", this.isReset);
      this.currentPage = 1;
      this.sportsID = "";
      this.tronmID = "";
      this.teamID = "";

      // if (!this.temparr.includes(option.name)) {
      //  this.temparr.push(option.name);
      // }
      if (!this.wait) {
        this.wait = true;
        // this.defaultValue = option.name;
        this.errdiv = false;

        try {
          // let switchStatus = await switchNetwork({
          //   chainId: option.chain_id,
          //   chainName: option.name,
          //   rpcUrls: [option.rpc_urls],
          //   blockExplorerUrls: [option.blockexplorer_urls],
          // });

          // if (switchStatus) {
          this.networkId = networkId;
          this.currentPage = 1;
          this.upcomings = [];
          this.getUpcomingmatch(
            1,
            this.sportsID ? this.sportsID : "",
            this.tronmID ? this.tronmID : "",
            this.teamID ? this.teamID : "",
            this.networkId
          );
          this.wait = false;
          // } else {
          //   // this.errdiv = true;
          //   this.spinner = false;
          //   this.errop = true;
          //   this.ermsg = "Please switch network";
          //   // this.defaultValue = "hsdbh";
          //   this.defaultValue = oldValue;
          //   console.log("old value listener page", this.defaultValue, oldValue);
          //   this.wait = false;
          //   // return false;
          // }
        } catch (error) {
          console.log("Error while update MM", error);
          this.wait = false;
        }
      }
    },
    async onSelectSport(sportId) {
      // console.log("already entered");
      this.isReset = false;
      this.spinner = true;
      this.sportsID = sportId;
      let respon = await APIService.gettorunsdrop(
        sportId,
        this.networkId ? this.networkId : "",
        0
      );
      this.tournament = respon.data.tournaments;
      this.istournament = false;
      this.isClear = true;
      this.currentPage = 1;
      this.upcomings = [];
      this.getUpcomingmatch(
        1,
        this.sportsID,
        "",
        "",
        this.networkId ? this.networkId : ""
      );
    },
    async onSelecttrnmt(trnmId) {
      // console.log("Entered");
      this.isReset = false;
      this.spinner = true;
      this.tronmID = trnmId;
      let respon = await APIService.getteamNFTdrop(
        trnmId,
        this.networkId ? this.networkId : "",
        0
      );

      this.teams = respon.data.teams;
      this.ismatch = false;
      this.currentPage = 1;
      this.upcomings = [];
      this.getUpcomingmatch(
        1,
        this.sportsID,
        this.tronmID,
        "",
        this.networkId ? this.networkId : ""
      );
    },
    async onSelectteam(teamid) {
      this.isReset = false;
      this.spinner = true;
      this.currentPage = 1;
      this.upcomings = [];
      this.teamID = teamid;

      this.spinner = true;
      this.upcomings = [];
      this.getUpcomingmatch(
        1,
        this.sportsID,
        this.tronmID,
        this.teamID,
        this.networkId ? this.networkId : ""
      );
    },
    async getsportsdrop() {
      try {
        let respon = await APIService.getsportsdrop();

        this.sports = respon.data.sports;

        // for (let i = 0; i < respon.data.sports.length; i++) {
        //   this.sports.push(respon.data.sports[i].name);
        // }
      } catch (error) {
        this.spinner = false;
        this.errdiv = true;
      }
    },
    loadMore() {
      // console.log(
      //   "eneterred",
      //   this.bottomSpinner,
      //   this.currentPage,
      //   this.totalPages
      // ); // someValue
      if (!this.bottomSpinner && this.currentPage <= this.totalPages) {
        // console.log("called");
        // this.bottomSpinner = true;
        this.getUpcomingmatch(
          this.currentPage,
          this.sportsID ? this.sportsID : "",
          this.tronmID ? this.tronmID : "",
          this.teamID ? this.teamID : "",
          this.networkId ? this.networkId : ""
        );
      }
    },
    async getUpcomingmatch(
      page = 1,
      sportId = "",
      tourId = "",
      teamId = "",
      networkId = ""
    ) {
      try {
        // console.log("called")
        this.errdiv = false;
        page == 1 ? (this.spinner = true) : (this.bottomSpinner = true);
        let respon = await APIService.getMatchbyStatuswithFilter(
          "upcoming",
          page,
          sportId,
          tourId,
          teamId,
          networkId
        );
        this.bottomSpinner = false;
        // console.log("home respon", respon);
        if (respon.status == true) {
          // console.log("Upcoming", respon.data);
          this.upcomings.push(...respon.data.matches);
          this.totalPages = respon.data.totalPages;

          // console.log("this.upcomings", this.upcomings);

          this.currentPage++;
          if (this.upcomings.length < 1) {
            this.errdiv = true;
            this.spinner = false;
          }
          this.errdiv;
          this.spinner = false;
        } else {
          this.spinner = false;
          this.bottomSpinner = false;
          this.errdiv = true;
        }
      } catch (error) {
        console.log("Err", error);
        this.spinner = false;
        this.errdiv = true;
      }
    },
    async getallnetwork() {
      this.spinner = true;
      let respon = await APIService.getallnetwork();
      console.log("response");
      if (respon.data.networks.length > 0) {
        this.networks = respon.data.networks;
      } else {
        this.spinner = false;
        this.errdiv = true;
      }

      // let switchStatus = await switchNetwork({
      //   chainId: this.networks[0].chain_id,
      //   chainName: this.networks[0].name,
      //   rpcUrls: [this.networks[0].rpc_urls],
      //   blockExplorerUrls: [this.networks[0].blockexplorer_urls],
      // });

      // if (switchStatus) {
      this.defaultValue = respon.data.networks[0].name;
      // } else {
      //   this.errdiv = true;
      //   this.spinner = false;
      //   this.errop = true;
      //   this.ermsg = "Please switch network";
      //   return false;
      // }
    },
    clearActive() {
      let allEl = document.getElementsByClassName("child-level-1");
      for (var i = 0; i < allEl.length; i++) {
        allEl[i].classList.remove("active");
      }
    },
  },
  created() {
    // this.getUpcomingmatch();
    this.clearActive();
    this.getsportsdrop();
    this.getallnetwork();
  },
};
</script>
<style>
.leftAlg {
  margin-left: -5px !important;
}
.aplybtn {
  background: #ffda2b;
  border-radius: 8px;
  border: #ffda2b;
  width: 90px;
  height: 30px;
  float: right !important;
  /* margin-top: 9px !important;  */
}
.v-dropdown-item.selected {
  background-color: #ffb800 !important;
  color: #fff !important;
  font-family: "Roboto";
}
.v-dropdown-item {
  background: #ffe6a5 !important;
  color: #000000 !important;
  font-weight: 700;
  font-size: 18px;
  font-family: "Roboto";
}
.v-dropdown-container {
  background: #ffe6a5 !important;
  color: #000000 !important;
  font-weight: 700;
  font-size: 18px;
  font-family: "Roboto";
}
.v-select-toggle {
  background-color: #ffe6a5 !important;
  border-color: #ffe6a5 !important;
  color: #000000 !important;
  font-weight: 700;
  font-size: 18px;
  font-family: "Roboto";
}
.divclrs > div > div > div {
  background: linear-gradient(
    88.69deg,
    #0b4973 1.12%,
    #07344b 57.43%,
    #0b4973 114.92%
  );
}

.divclrs > div:nth-child(even) > div > div {
  background: #4e0659 !important;
}

.divclrs > div:nth-child(3n) > div > div {
  background: #7d0832;
}

.divclrs > div:nth-child(6n) > div > div {
  background: #7d0832;
}

.errmsg {
  font-weight: bold;
  margin: auto;
  color: white;
}

.alignLeftdiv {
  text-align: end !important;
}

.head1 {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding: 8px;
  color: #b9b9b9;
}

.head2 {
  font-family: Ubuntu !important;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  padding: 8px;
  color: #ffda2b;
}

.head3 {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  padding: 8px;
}

.cardpad {
  padding: 8px;
}
</style>