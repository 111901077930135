<template>
  <div id="App" class="bgcolor">
    <BootstrapSidebar :initial-show="initialShow" :links="links" :header="header" :fa="true"
      :no-close-on-route-change="false" @sidebarChanged="onSidebarChanged">
      <template v-slot:navbar>
        <nav class="navbar navbar-light topnav-bg">
          <a class="navbar-brand adjPad"> </a>
          <form class="form-inline adjPad">
            <button type="button" class="btn con-bg main-wallet" v-if="!userAddress" @click="lisitionNetwork()">
              <font-awesome-icon icon="fa-solid fa-wallet" />
              Connect Wallet
            </button>

            <p v-else class="adjPad" style="color: #ffda2b">
              <font-awesome-icon icon="fa-solid fa-wallet" /> :
              <b>
                {{
                    userAddress.slice(0, 5) +
                    "...." +
                    userAddress.slice(userAddress.length - 4, userAddress.length)
                }}
              </b>
              <span @click.stop.prevent="copyTestingCode">
                <font-awesome-icon icon="fa-solid fa-copy" />
              </span>
              <input type="hidden" id="testing-code" :value="userAddress" />
            </p>
          </form>
        </nav>
      </template>

      <template v-slot:content>
        <b-container class="wrapper">
          <router-view />
        </b-container>
      </template>
    </BootstrapSidebar>
    <ErrorShow :msg="ermsg" :open="errop"></ErrorShow>
  </div>
</template>

<script>
import Vue from "vue";
import BootstrapSidebar from "./components/sidebar.vue";
import { APIService } from "./apiservice.js";
import { forceConnectMM, listenAccountChange } from "./metamask";
import ErrorShow from "./components/ErrorShow.vue";
const { ethers } = require("ethers");
// <script href="./node_modules/progressive-image/dist/index.css" rel="stylesheet" type="text"/>
import Swal from "sweetalert2";

export default Vue.extend({
  name: "App",
  components: {
    BootstrapSidebar,
    ErrorShow,
  },
  data() {
    return {
      errop: false,
      ermsg: null,
      text: "This will get copied!fewjbfwhbh",
      userAddress: null,
      initialShow: true,
      header: "Matches",
      links: [
        {
          name: "Ongoing",
          href: { name: "ongoing" },
          faIcon: ["fas", "money-bill-trend-up"],
        },
        { name: "Upcoming", href: { name: "home" }, faIcon: ["fas", "clock"] },
        {
          name: "Completed",
          href: { name: "completed" },
          faIcon: ["fas", "ranking-star"],
        },
        {
          name: "Joined Matches",
          href: { name: "joinnedContest" },
          faIcon: ["fas", "id-badge"],
        },
      ],
    };
  },
  methods: {
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "success",
          title: "Copied Successfully",
        });
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        // alert('Oops, unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      // window.getSelection().removeAllRanges()
    },
    clear() {
      console.log("cleared");
      localStorage.clear();
    },

    onSidebarChanged() { },
    async getsports() {
      try {
        let sportsArray = [];
        let respon = await APIService.gettournament();
        // console.log("get resp", respon.data.tournaments);
        sportsArray = respon.data.tournaments;
        console.log("getsports", sportsArray);
        if (sportsArray[0] != null) {
          for (let i = 0; i < sportsArray.length; i++) {
            if (sportsArray[i] != null) {
              if (i == 0) {
                this.links.push({
                  name: sportsArray[i].sportName,
                  subheader: "Sports",
                  sportsLogo: sportsArray[i].logo,
                  children: sportsArray[i].tournaments,
                })
              } else {
                this.links.push({
                  name: sportsArray[i].sportName,
                  sportsLogo: sportsArray[i].logo,
                  children: sportsArray[i].tournaments,
                })
              };
            }
          }
        }
        this.links.push(
          {
            name: "Explore NFTS",
            subheader: "NFTS",
            href: { name: "explorerNFT" },
            faIcon: ["fas", "users"],
          },
          {
            name: "My NFTS",
            href: { name: "myNFT" },
            faIcon: ["fas", "cart-arrow-down"],
          },
          {
            name: "Transactions",
            subheader: "Transactions",
            href: { name: "transaction_history" },
            faIcon: ["fas", "arrow-right-arrow-left"],
          },
          {
            name: "FAQ",
            subheader: "Help",
            href: { name: "faq" },
            faIcon: ["fas", "fa-circle-question"],
          },
          {
            name: "Contact Us",
            href: { name: "contact" },
            faIcon: ["fas", "fa-address-card"],
          }
        );

      } catch (error) {
        console.log(error);
      }
    },
    clearActive() {
      let allEl = document.getElementsByClassName("child-level-1");
      for (var i = 0; i < allEl.length; i++) {
        allEl[i].classList.remove("active");
      }
    },
    async lisitionNetwork() {
      this.errop = false;
      let resp = await forceConnectMM();
      // console.log("ad rec", resp);
      if (resp.status) {
        let user = JSON.parse(localStorage.getItem("Authdata"));
        // console.log("userlocaldata", user);
        this.userAddress = user.userAddress;
      } else {
        this.errop = true;
        this.ermsg = "You are not connected with metamask";
        // Swal.fire({
        //   position: "center",
        //   icon: "error",
        //   title: resp.message,
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      }
    },
    checkuserAuth() {
      let user = JSON.parse(localStorage.getItem("Authdata"));
      let connected = localStorage.getItem("connected");
      if ((user && user.userAddress) || connected) {
        // this.userAddress = user.userAddress
        this.lisitionNetwork();
      }
    },
  },
  created() {
    this.getsports();
    this.clearActive();
    this.checkuserAuth();
    listenAccountChange();
  },
});
</script>
<style>
.wrapper {
  max-width: 100% !important;
}

.loadercss {
  /* height: 80vh; */

  margin-top: 20% !important;
}

.alignLeftdiv {
  margin-top: 5px;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.adjPad {
  color: white;
}

.con-bg {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  color: #121212;
  background-color: #ffda2b !important;
}

.main-wallet {
  font-weight: 900 !important;
  font-family: Ubuntu !important;
}

.topnav-bg {
  background-color: #232123;
}

.bgcolor {
  /* position: inherit; */
  /* height: 100% !important;
  width: 100%; */
  min-height: 100%;
  background-color: #333333;
}

.default-theme .list-group-item .sidebar-menu-item {
  background-color: #202626;
  width: fit-content;
  border-radius: 100px !important;
  color: #000;
  border: none;
  margin-left: 20px;
}

.headercls {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */

  color: #b9b9b9 !important;
}

.vue-bootstrap-sidebar .list-group-item .sidebar-menu-item.child-level-1 .link-name {
  display: inline;
  text-align: left;
  font-size: 14px !important;
}

.vue-bootstrap-sidebar .sidebar-header {
  padding: 0px;
}

.vue-bootstrap-sidebar.sidebar-visible {
  margin-left: 0;
  overflow: auto;
}

.fullLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
<style>
/* @font-face {
  font-family: "" Roboto "";
  src: local("" Roboto ""),
    url("./../assets/font/"Roboto"/RobotoCondensed-Regular.ttf")
      format("truetype");
} */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap");
</style>