<template>
  <div class="container">
    <div class="row">
          
         <div
        v-for="userNFT in visibleCompanies"
        :key="userNFT.id"
        :id="userNFT.id"
        class="col-md-3"
      >
      <div >
      
        <hr />
        
        <div class="profile-card-6">
          <img
            style="height: inherit"
            :src="userNFT.mainImg"
            class="img img-responsive"
          />
          <div class="profile-name">JOHN <br />DOE</div>
          <div class="profile-position">Lorem Ipsum Donor</div>
          <!-- <div class="profile-overview">
            <div class="profile-overview">
              <div class="row text-center">
                <div class="col-xs-4">
                  <h3>1</h3>
                  <p>Rank</p>
                </div>
                <div class="col-xs-4">
                  <h3>50</h3>
                  <p>Matches</p>
                </div>
                <div class="col-xs-4">
                  <h3>35</h3>
                  <p>Goals</p>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
         </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userNFT: [
        {
          id: 1,
          name: "Company A",
          mainImg:
            "https://images.unsplash.com/photo-1492288991661-058aa541ff43?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
        },
        {
          id: 2,
          name: "Company A",
          mainImg:
            "http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg",
        },
         {
          id: 3,
          name: "Company A",
          mainImg:
            "https://images.unsplash.com/photo-1492288991661-058aa541ff43?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
        },
        {
          id: 4,
          name: "Company A",
          mainImg:
            "http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg",
        },
      ],
    };
  },
  computed: {
    visibleCompanies() {
      return this.userNFT.slice(0, this.companiesVisible);
    },
  },
};
</script>
<style scoped>
.card-container {
  padding: 100px 0px;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.profile-card-6 {
  /* max-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer; */
  max-width: 230px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  height: 350px;
}

.profile-card-6 img {
  transition: all 0.15s linear;
}

.profile-card-6 .profile-name {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  padding: 15px 20px;
  background: linear-gradient(
    140deg,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(255, 255, 0, 0) 50%
  );
  transition: all 0.15s linear;
}

.profile-card-6 .profile-position {
  position: absolute;
  color: rgba(255, 255, 255, 0.4);
  left: 30px;
  top: 100px;
  transition: all 0.15s linear;
}

.profile-card-6 .profile-overview {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(255, 255, 0, 0)
  );
  color: #fff;
  padding: 50px 0px 20px 0px;
  transition: all 0.15s linear;
}

.profile-card-6 .profile-overview h3 {
  font-weight: bold;
}

.profile-card-6 .profile-overview p {
  color: rgba(255, 255, 255, 0.7);
}

.profile-card-6:hover img {
  filter: brightness(80%);
}

.profile-card-6:hover .profile-name {
  padding-left: 25px;
  padding-top: 20px;
}

.profile-card-6:hover .profile-position {
  left: 40px;
}

.profile-card-6:hover .profile-overview {
  padding-bottom: 25px;
}
</style>