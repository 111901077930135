
const { ethers } = require("ethers");
const { APIService } = require('./apiservice.js');

async function connectMetamask() {
    try {
        if (window.ethereum !== undefined) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            let resp = await provider.send("eth_requestAccounts", []);
            if (ethereum.isConnected()) {
                // console.log("mm res", ethereum.isConnected(), resp)
                if (resp.length > 0) {
                    let user = JSON.parse(localStorage.getItem("Authdata"));
                    if (user) {
                        return true;
                    } else {
                        let data = {
                            walletAddress: resp[0]
                        }
                        let APIresp = await APIService.getAuthToken(data)
                        if (APIresp.status) {
                            // console.log("APIresp", APIresp);
                            let Authdata = {
                                userAddress: resp[0],
                                tokenAddress: APIresp.data.token
                            }
                            localStorage.setItem("Authdata", JSON.stringify(Authdata));
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    catch (e) {
        console.log("MM error", e);
        return false;
    }
}

function checkusersession(wallet) {
    let user = JSON.parse(localStorage.getItem("Authdata"));
    if (user) {
        // console.log("user", user)
        if (user.userAddress != wallet) {
            localStorage.clear();
            return true
        } else {
            return false
        }
    } else {
        return false
    }

}
async function forceConnectMM(chainId) {
    // console.log("MM status", window.ethereum)
    if (window.ethereum !== undefined) {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            let resp = await provider.send("eth_requestAccounts", []);
            // console.log("self.userAddress", resp[0]);
            if (resp.length > 0) {
                if (!checkusersession(resp[0])) {
                    let data = {
                        walletAddress: resp[0]
                    }
                    let APIresp = await APIService.getAuthToken(data)
                    if (APIresp.status) {
                        // console.log("APIresp", APIresp);
                        let Authdata = {
                            userAddress: resp[0],
                            tokenAddress: APIresp.data.token
                        }

                        localStorage.setItem("Authdata", JSON.stringify(Authdata));

                        return APIresp
                    } else {
                        return APIresp
                    }
                } else {
                    return { message: "Cannot connect with metamask...!!" }
                }
            }
            // var isConnected = resp.length > 0;
            // localStorage.setItem("walletAddress", JSON.stringify(isConnected));
            // window.location.reload()
        } catch (e) {
            return { message: "Cannot connect with metamask...!!" }
        }
    } else {
        return { message: "Please install metamask...!!" }
    }
}

function listenAccountChange() {
    if (window.ethereum !== undefined) {
        window.ethereum.on('accountsChanged', function (accounts) {
            window.location.reload();
            localStorage.clear();
            if (accounts.length > 0) {
                localStorage.setItem("connected", true);
            }
        })
    }
}

async function switchNetwork(network) {
    // console.log("network details", network)
    try {
        let chainId = await getChainId();
        if (network.chainId == chainId) {
            return true
        } else {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        network
                        // {
                        //     chainId: '0x61',
                        //     chainName: 'Smart Chain - Testnet',
                        //     rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                        //     blockExplorerUrls: ['https://testnet.bscscan.com'],
                        //     nativeCurrency: {
                        //         symbol: 'BNB',
                        //         decimals: 18
                        //     }
                        // }
                    ]
                });
                let chainId = await getChainId();
                // console.log("network.chainId == chainId", network.chainId, chainId);
                if (network.chainId.toLowerCase() == chainId || network.chainId.toUpperCase() == chainId) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log("switch network err", e);
                return false
            }
        }
    } catch (error) {
        console.log("switch network err", error);
        return false
    }
}

async function getChainId() {
    if (window.ethereum !== undefined) {
        const MM = window.ethereum;
        const chainId = await MM.request({ method: "eth_chainId" });
        // const n = await provider.getNetwork();
        // console.log("Current chainid", chainId, n);
        return chainId;
    }
}

export {
    connectMetamask,
    forceConnectMM,
    listenAccountChange,
    getChainId,
    switchNetwork
} 