<template>
  <div>
    <vue-load-image>
      <img slot="image" class="card-img-top" :src="nftImage" />
      <img
        slot="preloader"
        class="card-img-top"
        src="https://www.shaw.sg/Content/Images/common/default/en-SG/loader.gif"
      />
      <div slot="error">error message</div>
    </vue-load-image>
  </div>
</template>
<script>
import VueLoadImage from "vue-load-image";
export default {
  components: {
    "vue-load-image": VueLoadImage,
  },
  props: {
    nftImage: String,
    nftName: String,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.card-img-top {
  /* width: 249px; */
  /* height: 249px !important; */
  aspect-ratio: 5 / 6 !important;
  border-radius: 8px;
}
.popimgs {
  height: 300px !important;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0rem !important;
}
</style>