<template>
  <div class="fullLoader" v-if="spinner">
    <Loader class="spin1"></Loader>
  </div>
  <div class="" v-else>
    <div class="details-container row">
      <div class="col-xl-12">
        <div class="head1">Discover</div>
        <div class="head2">MY NFT Details</div>
      </div>
      <div class="col-xl-3">
        <viewer :options="{toolbar:false,navbar:false,title:false}">
          <ImageLoader
            class="nft-img my-3 my-xl-0"
            :nftImage="image_url"
          ></ImageLoader>
        </viewer>
      </div>
      <div class="col-xl-7 details-container-left">
        <h2>{{ nftName }}</h2>
        <P>{{ nftdisp }}</P>
        <div class="nft-details-content">
          <h6>Bought for :</h6>
          <span>
            <img :src="buy_token_logo" alt="" />
            <h6>{{ nft_price }} {{ buy_token_symbol }}</h6>
            <div class="dot mx-2"></div>
            <h6>{{ network_name }}</h6>
          </span>
        </div>
        <div class="nft-details-content">
          <h6>Tournament Name :</h6>
          <span>
            <h6>&nbsp;{{ tournamentName }}</h6></span
          >
        </div>
        <hr style="color: gray;
    border-bottom: 1px solid yellow;">
      <div class="row caldiv">
        <div class="col-md-4">
          Max Usage : {{this.max_usage}}
        </div>
        <div class="col-md-4">
          Used : {{this.total_used}}
        </div>
        <div class="col-md-4">
          Available : {{this.available}}
        </div>
      </div>
      </div>
    </div>
    <div
      class="nft-detail-footer mt-4"
      style="text-align: center"
      v-if="transDate.length > 0"
    >
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Match</th>
            <th>Match Date</th>
            <th>Outcome</th>
            <th>Winnings</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(txns, index) in transDate" :key="index">
            <td class="date">{{ moment(txns.nft_used_time) }}</td>
            <td class="matches">{{ txns.nft_used_match }}</td>
            <td class="matches">{{ moment(txns.match_time) }}</td>
            <td class="result">
              <span v-if="txns.outcome == 'Draw'">
                Draw
                <font-awesome-icon
                  icon="fa-solid fa-equals"
                  style="color: gold"
                />
              </span>
              <span v-else-if="txns.outcome == 'Won'">
                Won
                <font-awesome-icon
                  icon="fa-solid fa-trophy"
                  style="color: gold"
                />
                <!-- 4 USDT -->
              </span>
              <span v-else-if="txns.outcome == 'Lost'">
                Lost <img src="../../assets/image/loss.png" alt="" />
              </span>
              <span v-else> Pending </span>
            </td>
            <td class="price">
              {{ txns.winnings }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="no-data my-3">
      <h4>No Transaction Available</h4>
    </div>
  </div>
</template>

<script>
import { APIService } from "../apiservice.js";
import ImageLoader from "../components/imageLoader.vue";
import Loader from "../components/loader.vue";
import moment from "moment";
import { NFTContract } from "@/helpers/nftContract";

export default {
  components: {
    ImageLoader,
    Loader,
  },
  data() {
    return {
      total_used:null,
      max_usage:null,
      available:null,
      tournamentName: null,
      transDate: [
       
        //   {
        //   nft_used_time: "10/04/2020",
        //   nft_used_match: "CSK VS MI",
        //   outcome:"Won",
        //   winnings:"213 USDT"
        // },
      ],
      image_url: null,
      nftName: null,
      nftdisp: null,
      buy_token_logo: null,
      nft_price: null,
      network_name: null,
      buy_token_symbol: null,
      spinner: true,
    };
  },
  mounted() {},
  methods: {
    moment: function (date) {
      // console.log(moment(date).format("YYYY-MM-DD hh:mm:ss"));
      return moment(date).format("DD-MM-YYYY hh:mm:ss A");
    },
    async myusage() {
      this.spinner = true;
      // console.log("router", this.$route.params.id);
      // console.log("router type", typeof this.$route.params.id);
      this.Id = this.$route.params.id;
      let respon = await APIService.getMynftusage(this.Id);
      this.transDate.push(...respon.data.txns);
        //  console.log("transDate", respon.data);
      // await this.avail(respon.data.nft_contract);
         this.total_used =  respon.data.used
      this.max_usage = respon.data.max_usage
      this.available = respon.data.available
      // console.log("transDate", respon.data);
      this.tournamentName = respon.data.tournament_name;
      this.image_url = respon.data.image_url;
      this.nftName = respon.data.nft_name;
      this.nftdisp = respon.data.description;
      this.buy_token_logo = respon.data.buy_token_logo;
      this.nft_price = respon.data.nft_price;
      this.network_name = respon.data.network_name;
      this.buy_token_symbol = respon.data.buy_token_symbol;
      this.spinner = false;
      // console.log("API Resp", this.transDate);
    },
    // async avail(nft_contract) {
    //   let getNftInfo = await NFTContract.getNftInfo(nft_contract);
    //   this.total_used = getNftInfo.used
    //   this.max_usage = getNftInfo.maxUsage
    //   this.available = getNftInfo.available
    //   console.log("getNftInfo", getNftInfo);
    // },
  },
  created() {
    this.myusage();
  },
};
</script>

<style>
.caldiv{
      color: white;
    font-size: 18px;
    font-weight: 500;
}
.nft-img {
  width: 200px !important;
  /* height: 240px !important; */
}
.details-container {
  padding-left: 50px;
}
.nft-details-container {
  padding: 30px;
  /* margin-left: 40px; */
}
.details-container-left > h2 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  /* line-height: 28px; */
  color: #ffffff;
}
.details-container-left > p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}
.no-data {
  text-align: center;
  color: #ffff;
}
.nft-details-content {
  display: flex;
  align-items: center;
  /* justify-content: start; */
}
.nft-details-content > h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 23px; */

  color: #ecd401;
}
.nft-details-content > span {
  display: flex;
  align-items: center;
}
.nft-details-content > span > div {
  display: flex;
  align-items: center;
}
.nft-details-content > span img {
  width: 30px;
  height: 30px;
  margin: 0 6px;
  border-radius: 50%;
}
.nft-details-content > span .dot {
  width: 10px;
  height: 10px;
  background-color: #49e5ef;
  border-radius: 50%;
}
.nft-details-content > span h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 23px; */
  color: #49e5ef;
  margin-bottom: 3px;
}
.nft-detail-footer table {
  width: 100%;
}
.nft-detail-footer table tbody {
  background-color: #0e0e0e;
}
.nft-detail-footer table thead tr {
  /* border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important; */
  background-color: #262626;
}
/* .nft-detail-footer table tbody tr td {
  padding: 15px !important;
} */

/* curvy border for table */

.nft-detail-footer table thead th:first-child {
  border-top-left-radius: 15px;
}
.nft-detail-footer table thead th:last-child {
  border-top-right-radius: 15px;
}
.nft-detail-footer table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}
.nft-detail-footer table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}
.nft-detail-footer table tbody {
  border-right: 60px solid transparent;
  border-left: 60px solid transparent;
}

.nft-detail-footer table th {
  /* border: 1px solid #dddddd; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #89ed9f;
  padding: 20px 10px;
}
.nft-detail-footer table td {
  padding: 15px;
  border-bottom: 1px solid #dddddd;
}
.nft-detail-footer table tbody tr:last-child td {
  border-bottom: none !important;
}
.nft-detail-footer table tbody .date {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #b8b8b8;
  padding: 10px;
}
.nft-detail-footer table tbody .matches,
.nft-detail-footer table tbody .result,
.nft-detail-footer table tbody .price {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  padding: 25px;
  color: #ffffff;
}
.nft-detail-footer table tbody .price img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.nft-detail-footer table tbody .result .i-win svg {
  color: #ecd401;
  margin: 0 10px;
}
.nft-detail-footer table tbody .result .i-lost svg {
  color: #f43756 !important;
  margin: 0 10px;
}
</style>