const API_URL = "https://prediction-api.rage.fan/api/";
// const API_URL = "https://de91-118-185-191-21.ngrok.io/api/";
const Server_API = "http://3.110.84.211:3000/api/users/"
import axios from "axios";
import { authHeader } from "./helpers/authHeader";
export const APIService = {

    getMatchbyStatuswithFilter(matchstatus, page = 1, sportId, tourId, teamId, networkId) {
        const url = `${API_URL}auth/getMatch?page=${page}&status=${matchstatus}&sport=${sportId}&tourId=${tourId}&teamId=${teamId}&network=${networkId}`;
        return axios.get(url).then(res => res.data);
    },
    getMatchbyStatus(matchstatus, page = 1) {
        const url = `${API_URL}auth/getMatch?page=${page}&status=${matchstatus}`;
        return axios.get(url).then(res => res.data);
    },
    getNFT(page, sportId, tourId, teamId, networkId) {
        const url = `${API_URL}auth/getNfts?page=${page}&sport=${sportId}&tourId=${tourId}&teamId=${teamId}&network=${networkId}`;
        return axios.get(url).then(res => res.data);
    },
    buyNFT(data) {
        const url = `${API_URL}users/buyNft`;
        return axios.post(url, data, authHeader()).then(res => res.data);
    },
    getMYNFT(page, networkId) {
        const url = `${API_URL}users/getUserNft?page=${page}&network=${networkId}`;
        return axios.get(url, authHeader()).then(res => res.data);
    },
    gettournament() {
        const url = `${API_URL}auth/getTournaments`;
        return axios.get(url).then(res => res.data);
    },
    getsports() {
        const url = `${API_URL}auth/getSports`;
        return axios.get(url).then(res => res.data);
    },
    gettournamentByID(page, ID) {
        const url = `${API_URL}auth/getMatch?page=${page}&tourId=${ID}&status=upcoming`;
        return axios.get(url).then(res => res.data);
    },

    betNFT(data) {
        const url = `${API_URL}users/bet`;
        return axios.post(url, data, authHeader()).then(res => res.data);
    },

    getjoinedcontest(status) {
        const url = `${API_URL}users/joinedMatches?status=${status}`;
        return axios.get(url, authHeader()).then(res => res.data);
    },

    getAuthToken(data) {
        const url = `${API_URL}auth/register`;
        return axios.post(url, data).then(res => res.data);
    },
    getcheckexistsNFT(teamID, matchID) {
        const url = `${API_URL}users/bettingExists?teamId=${teamID}&matchId=${matchID}`;
        return axios.get(url, authHeader()).then(res => res.data);
    },
    getNetwork(network) {
        const url = `${API_URL}/getNetworks?id=${network}`;
        return axios.get(url, authHeader()).then(res => res.data);
    },
    getTransactionHistory(page) {
        const url = `${API_URL}users/getTransactions?page=${page}`;
        return axios.get(url, authHeader()).then(res => res.data);
    },
    getsportsdrop() {
        const url = `${API_URL}auth/getSports`;
        return axios.get(url).then(res => res.data);
    },

    gettorunsdrop(sportsID, networkId, nft) {
        console.log("nft", nft)
        const url = `${API_URL}auth/getToursAdmin?sportId=${sportsID}&network=${networkId}&nft=${nft}`;
        return axios.get(url).then(res => res.data);
    },

    getteamNFTdrop(trnmID, networkId, nft) {
        const url = `${API_URL}auth/getTeamsForFilter/${trnmID}?network=${networkId}&nft=${nft}`;
        return axios.get(url).then(res => res.data);
    },

    getMynftusage(Id) {
        const url = `${API_URL}users/getNftUsages?nftId=${Id}`;
        return axios.get(url, authHeader()).then(res => res.data);
    },

    getallnetwork() {
        const url = `${API_URL}auth/getAllNetworks`;
        return axios.get(url).then(res => res.data);
    }
}